<template>
    <div class="header">
        <div class="h-wrap">
            <a class="logo" @click="movePage('/info')"><img src="@/assets/image/rebuild/ami_logo.png" alt=""></a>
            <div class="info njoin">
                <div class="login cursor" @click="movePage('/auth')"><span>로그인</span></div>
                <div class="join cursor" @click="movePage('/auth/regist')"><span>회원가입</span></div>
            </div>
        </div>
    </div>
</template>

<script>

    import EventBus from "@/utils/event-bus";
    export default {
        name: 'LogoutHeader',
        components: {},
        mixins: [],
        props: {
            msg: String,
        },
        data() {
            return {
            }
        },
        created(){

        },
        methods:{
            movePage(path) {
                if(this.$route.path === path) {
                    return ;
                }
                this.$router.push(path);
                // location.href = path;
            },
            openModal() {
                EventBus.$emit('openMemberInfoModal', 1)
            },
        },
        watch: {

        }
    }

</script>
